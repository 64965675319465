/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import tw, { css, theme } from "twin.macro";
import PropTypes from "prop-types";
import { DocumentContext } from "~context/DocumentContext.jsx";
import * as A from "~components/styles/Animations.jsx";
import * as T from "~components/styles/Typography.jsx";
import * as Icon from "~components/svg/Icons.jsx";

const EXPONENTIAL_MIN = 0;
const EXPONENTIAL_MAX = 10000;
const EXPONENTIAL_STEP = 1;

const DoubleSlider = ({
  active,
  excluded,
  exponential,
  min,
  max,
  onUpdate,
  step,
  stringParser,
  prefix,
  suffix,
  title,
  tip,
  setTip,
  setTipActive,
  syncWith,
  visible,
  widget
}) => {
  const { isDesktop } = useContext(DocumentContext);

  const minRef = useRef();
  const maxRef = useRef();

  const [activeSlider, setActiveSlider] = useState(null);
  const [interacted, setInteracted] = useState(false);
  const [minValue, setMinValue] = useState(exponential ? EXPONENTIAL_MIN : min);
  const [maxValue, setMaxValue] = useState(exponential ? EXPONENTIAL_MAX : max);
  const [minValueStr, setMinValueStr] = useState(
    exponential ? EXPONENTIAL_MIN : min
  );
  const [maxValueStr, setMaxValueStr] = useState(max);

  let pointerEvents = `none`;

  if (!widget || (visible && !excluded)) {
    pointerEvents = `auto`;
  }

  const RANGE_SLIDER_CSS = `
-webkit-appearance: none;
width: 100%;
background: transparent;
pointer-events: none;

&:focus {
  outline: none;
}

&:nth-of-type(1) {
  &::-webkit-slider-thumb {
    z-index: 20;
  }

  &::-moz-range-thumb {
    z-index: 20;
  }

  &::-ms-thumb {
    z-index: 20;
  }
}

&::-webkit-slider-thumb {
  -webkit-appearance: none;

  
  pointer-events: ${pointerEvents};

  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: ${theme`colors.black`};
  border: 1px solid ${theme`colors.white`};
  cursor: pointer;
  z-index: 999;
}

&::-moz-range-thumb {
  
  pointer-events: ${pointerEvents};

  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: ${theme`colors.black`};
  border: 1px solid ${theme`colors.white`};
  cursor: pointer;
  z-index: 999;
}

&::-ms-thumb {
  pointer-events: ${pointerEvents};

  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background: ${theme`colors.black`};
  border: 1px solid ${theme`colors.white`};
  cursor: pointer;
  z-index: 999;
}
`;

  //

  const toExponent = (value) => {
    if (value <= 0) {
      return 0;
    }

    return (
      Math.ceil(
        parseInt(
          Math.round(Math.exp((Math.log(1000) / 100) * (value / 100))) *
            (max / 1000)
        ) / step
      ) * step
    );
  };

  const updateData = () => {
    if (!interacted) {
      setInteracted(true);
    }

    setActiveSlider(null);

    let trueMin = minValue;
    let trueMax = maxValue;

    if (exponential) {
      trueMin = toExponent(minValue);
      trueMax = toExponent(maxValue);
    }

    onUpdate({
      key: title,
      value: { min: trueMin, max: trueMax }
    });
  };

  const updateMinValue = (value) => {
    if (!exponential) {
      setMinValueStr(value);
    } else {
      setMinValueStr(toExponent(value));
    }

    if (value >= maxValue && maxValue < max) {
      setMaxValue(exponential ? value + EXPONENTIAL_STEP : value + step);

      if (exponential) {
        setMaxValueStr(toExponent(value + step));
      } else {
        setMaxValueStr(value + step);
      }
    }
  };

  const updateMaxValue = (value) => {
    if (!exponential) {
      setMaxValueStr(value);
    } else {
      setMaxValueStr(toExponent(value));
    }

    if (parseInt(value) <= minValue && minValue > min) {
      setMinValue(exponential ? value - EXPONENTIAL_STEP : value - step);

      if (exponential) {
        setMinValueStr(toExponent(value - step));
      } else {
        setMinValueStr(value - step);
      }
    }
  };

  //

  useEffect(() => {
    if (exponential || typeof syncWith?.min === `undefined`) {
      return;
    }

    setMinValue(syncWith.min);
    updateMinValue(syncWith.min);
  }, [syncWith?.min]);

  useEffect(() => {
    if (exponential || typeof syncWith?.max === `undefined`) {
      return;
    }

    setMaxValue(syncWith.max);
    updateMaxValue(syncWith.max);
  }, [syncWith?.max]);

  useEffect(() => {
    if (activeSlider === `min`) {
      updateMinValue(minValue);
    }
  }, [minValue]);

  useEffect(() => {
    if (activeSlider === `max`) {
      updateMaxValue(maxValue);
    }
  }, [maxValue]);

  //

  return (
    <article
      css={[
        css`
          padding-top: ${widget ? `1rem` : `1.5rem`};
          padding-right: ${widget ? `1rem` : `0`};
          padding-bottom: ${widget ? `1.5rem` : `2rem`};
          padding-left: ${widget ? `1rem` : `0`};
        `,
        tw`w-full relative block text-white`
      ]}
    >
      {!widget && isDesktop() && (
        <div css={[tw`h-5 relative flex items-center mb-1`]}>
          <T.Heading
            font="b2"
            level="3"
            styles={[tw`block text-white uppercase`]}
          >
            {title}
          </T.Heading>

          {tip && (
            <button
              type="button"
              css={[tw`w-5 h-5 absolute top-0 left-0 block -ml-6`]}
              onClick={() => {
                setTip({ ...tip, title });
                setTipActive(true);
              }}
            >
              <div tw="w-full h-full flex items-center justify-center">
                <Icon.Tip styles={[tw`h-3`]} />
              </div>
            </button>
          )}
        </div>
      )}

      <div
        css={[
          css`
            transition: opacity 0.15s ${A.EASING_CUBIC};

            opacity: ${excluded ? `0.25` : `1`};
          `,
          tw`w-full relative`
        ]}
      >
        <div tw="w-full h-8 relative flex items-center justify-center">
          <div
            css={[
              css`
                height: 1px;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
              `,
              tw`w-full absolute bg-white`
            ]}
          />

          <input
            ref={minRef}
            css={[
              css`
                ${RANGE_SLIDER_CSS}
              `,
              tw`w-full h-full absolute`
            ]}
            onChange={(e) => {
              setActiveSlider(`min`);
              setMinValue(parseInt(e.currentTarget.value));
            }}
            onMouseUp={updateData}
            onTouchEnd={updateData}
            min={exponential ? EXPONENTIAL_MIN : min}
            max={exponential ? EXPONENTIAL_MAX - EXPONENTIAL_STEP : max - step}
            step={exponential ? EXPONENTIAL_STEP : step}
            type="range"
            value={minValue}
          />

          <input
            ref={maxRef}
            css={[
              css`
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                ${RANGE_SLIDER_CSS}
              `,
              tw`w-full h-full absolute`
            ]}
            onChange={(e) => {
              setActiveSlider(`max`);
              setMaxValue(parseInt(e.currentTarget.value));
            }}
            name="max"
            onMouseUp={updateData}
            onTouchEnd={updateData}
            min={exponential ? EXPONENTIAL_MIN + EXPONENTIAL_STEP : min + step}
            max={exponential ? EXPONENTIAL_MAX : max}
            step={exponential ? EXPONENTIAL_STEP : step}
            type="range"
            value={maxValue}
          />
        </div>

        <div
          css={[
            css`
              opacity: ${active ? `1` : `0.4`};
            `,
            tw`w-full relative flex justify-between`
          ]}
        >
          <div
            css={[
              css`
                width: 8rem;
                justify-content: ${suffix ? `end` : `space-between`};

                &:after {
                  content: " ";

                  height: 1px;
                  position: absolute;
                  right: 0;
                  bottom: -4px;
                  left: 0;
                  background: ${theme`colors.white`};
                  z-index: -10;
                }
              `,
              tw`relative flex`
            ]}
          >
            {prefix && (
              <T.Heading font={`${prefix === `$` ? `4` : `b1`}`}>
                {prefix}
              </T.Heading>
            )}

            <T.Heading font="4">
              {exponential ? minValueStr : stringParser(minValue)}
            </T.Heading>

            {suffix && <T.Heading font="4">{suffix}</T.Heading>}
          </div>

          <div
            css={[
              css`
                width: 8rem;
                justify-content: ${suffix ? `end` : `space-between`};

                &:after {
                  content: " ";

                  height: 1px;
                  position: absolute;
                  right: 0;
                  bottom: -4px;
                  left: 0;
                  background: ${theme`colors.white`};
                }
              `,
              tw`relative flex text-right`
            ]}
          >
            {prefix && (
              <T.Heading font={`${prefix === `$` ? `4` : `b1`}`}>
                {prefix}
              </T.Heading>
            )}

            <T.Heading font="4">
              {exponential ? maxValueStr : stringParser(maxValue)}
            </T.Heading>

            {suffix && <T.Heading font="4">{suffix}</T.Heading>}
          </div>
        </div>
      </div>
    </article>
  );
};

DoubleSlider.defaultProps = {
  active: false,
  excluded: false,
  syncWith: null,
  onUpdate: () => {},
  step: 1,
  stringParser: (valueStr) => valueStr,
  visible: false
};

DoubleSlider.propTypes = {
  active: PropTypes.bool,
  excluded: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onUpdate: PropTypes.func,
  step: PropTypes.number,
  stringParser: PropTypes.func,
  syncWith: PropTypes.shape({}),
  visible: PropTypes.bool
};

export default DoubleSlider;
